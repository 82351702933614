import { lazy } from 'react';

import { ProtectedRoute } from '../common/components/authentication/protected';
import { PermittedRoute } from '../common/components/permissions/permittedRoute';
import Loadable from '../common/components/loadable';

const Suggestions = Loadable(lazy(() => import('../common/pages/suggestions/suggestions')));
const PersonalSettings = Loadable(lazy(() => import('../common/pages/settings/personal')));
const UserLayout = Loadable(
  lazy(() =>
    import('../common/components/layout/user-layout').then((module) => ({
      default: module.UserLayout,
    })),
  ),
);

export const UserRoutes = {
  path: '/',
  element: (
    <ProtectedRoute>
      <PermittedRoute accessRules={{ roles: ['PUBLIC'] }}>
        <UserLayout />
      </PermittedRoute>
    </ProtectedRoute>
  ),
  children: [
    {
      path: '/',
      element: <Suggestions mode="web" />,
    },
    {
      path: '/suggestions',
      element: <Suggestions mode="web" />,
    },
    {
      path: 'settings',
      element: <PersonalSettings mode="web" />,
    },
  ],
};
