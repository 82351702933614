import { lazy } from 'react';

import { ProtectedRoute } from '../common/components/authentication/protected';
import { PermittedRoute } from '../common/components/permissions/permittedRoute';
import Loadable from '../common/components/loadable';

const AdminHome = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/Home')),
);
const GlobalSettings = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/global-settings')),
);
const Usecases = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/usecase')),
);
const AdminUsers = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/users')),
);
const UserPage = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/user-page')),
);
const IQVIADataUpload = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/iqvia')),
);
const AdminGroups = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/groups')),
);
const AdminAudiences = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/audiences')),
);
const AdminCycles = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/cycles')),
);
const SuggestionsSettings = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/suggestions-settings')),
);
const Audit = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/audit')),
);
const Countries = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/countries')),
);
const HCPAccounts = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/accounts/hcp')),
);
const AdminCyclesPlans = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/cycles-plans')),
);
const AdminCyclesPlansTargets = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/cycles-plans-targets')),
);
const DysportUpload = Loadable(
  lazy(() => import('../administration/pages/administration/dashboard/dysport-upload')),
);

export const adminRoutes = {
  path: '/administration',
  element: (
    <ProtectedRoute>
      <PermittedRoute
        accessRules={{ roles: ['country', 'data', 'it', 'platform', 'global_admin'] }}
      >
        <AdminHome mode="web" />
      </PermittedRoute>
    </ProtectedRoute>
  ),
  children: [
    {
      path: 'settings/global',
      element: <GlobalSettings mode="web" />,
    },
    {
      path: 'settings/usecases',
      element: <Usecases mode="web" />,
    },
    {
      path: 'settings/suggestions',
      element: <SuggestionsSettings mode="web" />,
    },
    {
      path: 'settings/countries',
      element: <Countries mode="web" />,
    },
    {
      path: 'data/iqvia/upload',
      element: <IQVIADataUpload mode="web" />,
    },
    {
      path: 'data/audit',
      element: <Audit mode="web" />,
    },
    {
      path: 'data/veeva/cycle',
      element: <AdminCycles mode="web" />,
    },
    {
      path: 'data/veeva/dysport-upload',
      element: <DysportUpload />,
    },
    {
      path: 'data/veeva/cycles-plans',
      element: <AdminCyclesPlans mode="web" />,
    },
    {
      path: 'data/veeva/cycle-plans-targets',
      element: <AdminCyclesPlansTargets mode="web" />,
    },
    {
      path: 'provisioning/users',
      element: <AdminUsers mode="web" />,
    },
    {
      path: 'provisioning/users/:userId',
      element: <UserPage />,
    },
    {
      path: 'provisioning/groups',
      element: <AdminGroups mode="web" />,
    },
    {
      path: 'provisioning/audiences',
      element: <AdminAudiences mode="web" />,
    },
    {
      path: 'accounts/hcps',
      element: <HCPAccounts mode="web" />,
    },
  ],
};
