import { lazy } from 'react';

import Loadable from '../common/components/loadable';
import Layout from '../common/components/layout';

const Login = Loadable(lazy(() => import('../common/pages/login/login')));
const ResetPassword = Loadable(lazy(() => import('../common/pages/login/reset-password')));
const Signup = Loadable(lazy(() => import('../common/pages/login/signup')));
const Error404 = Loadable(lazy(() => import('../common/pages/errors/404')));
const MultiAccount = Loadable(lazy(() => import('../common/pages/login/multi-account')));
const Config = Loadable(lazy(() => import('../common/pages/config')));
const OverviewPage = Loadable(lazy(() => import('../common/pages/overview-page')));

export const AuthRoutes = {
  path: '/',
  element: <Layout />,
  children: [
    {
      path: 'login',
      element: <Login mode="web" callback={() => ({})} />,
    },
    {
      path: 'logout',
      element: <Login mode="web" callback={() => ({})} />,
    },
    {
      path: 'reset-password/:resetToken?',
      element: <ResetPassword />,
    },
    {
      path: 'signup',
      element: <Signup mode="web" callback={() => ({})} />,
    },
    {
      // ms-teams config
      path: 'config',
      element: <Config />,
    },
    {
      path: 'overview',
      element: <OverviewPage />,
    },
    {
      path: 'my/accounts',
      element: <MultiAccount />,
    },
    {
      path: '*',
      element: <Error404 />,
    },
  ],
};
