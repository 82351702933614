import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IPagination } from '../../interfaces/IPagination';

export const getUsecases = async ({ pageSize, offset }: IPagination): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/usecases`, {
    params: {
      page_size: pageSize,
      offset,
      $count: true,
    },
  });
};

export const getUsecase = async (usecaseId: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/usecases/${usecaseId}`);
};

export const createUsecase = async (data: any): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/usecases`, data);
};

export const getUsecasesConfigurations = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/usecases/configurations`);
};

export const updateUsecase = async (usecaseId: string, data: any): Promise<AxiosResponse> => {
  return instance.put(`${config.REACT_APP_API_VERSION}/usecases/${usecaseId}`, data);
};

export const deleteUsecase = async (usecaseId: string): Promise<AxiosResponse> => {
  return instance.delete(`${config.REACT_APP_API_VERSION}/usecases/${usecaseId}`);
};
