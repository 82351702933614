export const getCountryFromTimezone = (timezone: string) => {
  const timezoneMap: any = {
    'Europe/Paris': 'France',
    'Africa/Algeria': 'Algeria',
  };
  return timezoneMap[timezone] || 'N/A';
};

export const objectCasting = (arr: any) => {
  if (!arr) {
    return [];
  }

  return arr.map((obj: any) => ({
    id: obj.id,
    username: obj.userName,
    displayName: obj.displayName,
    country: getCountryFromTimezone(obj.timezone),
    email: obj.email,
    active: obj.active,
    lastModified: obj.audit?.updated_at,
  }));
};

export const getQueryParams = (searchText: string, filterOptions: any[]): string => {
  const selectedFilters = filterOptions.filter((filter: any) => filter.value);
  const words = searchText.split(' ');

  const hasGivenName = selectedFilters.some((filter: any) => filter.query === 'given_name');
  const hasFamilyName = selectedFilters.some((filter: any) => filter.query === 'family_name');

  if (selectedFilters.length > 0) {
    return selectedFilters
      .map((filter: any) => {
        if (hasGivenName && hasFamilyName) {
          return words
            .map((word: string) => `given_name~${word} OR family_name~${word}`)
            .join(' OR ');
        }
        return `${filter.query}~${searchText}`;
      })
      .join(' OR ');
  } else if (searchText) {
    return words.map((word: string) => `given_name~${word} OR family_name~${word}`).join(' OR ');
  }
  return '';
};
