import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
// import { searchUsers } from '../apis/users';
import { searchUsers } from '@administration/common/apis/users';

const initialState = {
  users: [],
  filter: {
    name: '',
  },
  suggestions: [],
  count: 0,
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk<any, { name: string }>(
  'users/fetchUsers',
  async ({ name = '' }) => {
    try {
      const response = await searchUsers(name, 'Employee');
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
      throw new Error('An error occurred while fetching suggestions');
    } catch (error) {
      throw new Error('An error occurred while fetching suggestions');
    }
  },
);

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setfilter(state, action: PayloadAction<string>) {
      state.filter.name = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.values;
        state.count = action.payload.count || 0;
        state.error = null;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export const { setfilter } = usersSlice.actions;
export default usersSlice.reducer;
