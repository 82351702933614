import { AxiosResponse } from 'axios';
import { instance } from '../../../common/apis/baseApi';
import { config } from '../../../common/config/configurations';
import { FetchUsersParams } from '../../interfaces/IAdminUsers';

export const searchUsers = async (query: any): Promise<AxiosResponse> => {
  if (query && (query.includes('OR') || query.includes('AND'))) {
    query = `((${query}) AND user_type==Employee)`;
  } else if (query) {
    query = `(${query} AND user_type==Employee)`;
  } else {
    query = 'user_type==Employee';
  }
  return instance.get(`${config.REACT_APP_API_VERSION}/users/search`, {
    params: {
      query,
    },
  });
};

export const getUsers = ({
  pageSize,
  offset,
  cursor,
}: FetchUsersParams): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/users`, {
    params: {
      page_size: pageSize,
      offset,
      cursor,
      filter: 'user_type = Employee',
      $count: true,
    },
  });
};

export const deleteUser = (id: string): Promise<AxiosResponse> => {
  return instance.delete(`${config.REACT_APP_API_VERSION}/users/${id}`);
};

export const getTotalUsers = (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/users`, {
    params: {
      $count: true,
    },
  });
};

export const getAllUsers = (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/users`, {
    params: {
      page_size: -1,
      filter: 'user_type = Employee',
    },
  });
};

export const impersonateUser = (userId: string): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/authentication/oauth2/grant/${userId}`);
};

export const unImpersonateUser = (): Promise<AxiosResponse> => {
  return instance.delete(`${config.REACT_APP_API_VERSION}/authentication/oauth2/grant`);
};

export const getUserDetails = (id: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/users/${id}`, {
    params: {
      expand: 'addresses',
    },
  });
};

// export const patchUserDetail = (id: string, payload: any): Promise<AxiosResponse> => {
//   return instance.patch(`${config.REACT_APP_API_VERSION}/users/${id}`, payload);
// };

export const patchUserEmail = ({
  userId,
  emailId,
  payload,
}: {
  userId: string;
  emailId: string;
  payload: any;
}): Promise<AxiosResponse> => {
  return instance.patch(
    `${config.REACT_APP_API_VERSION}/users/${userId}/emails/${emailId}`,
    payload,
  );
};

export const patchUserAddress = ({
  userId,
  addressId,
  payload,
}: {
  userId: string;
  addressId: string;
  payload: any;
}): Promise<AxiosResponse> => {
  return instance.patch(
    `${config.REACT_APP_API_VERSION}/users/${userId}/addresses/${addressId}`,
    payload,
  );
};
