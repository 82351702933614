import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import ThemeWrapper from './common/theme';
import { store } from './store';
import router from './routes';
import { Toast } from './administration/components/toast/Toast';
import { ImpersonatedToast } from './administration/components/impersonateToast/ImpersonateToast';
import { LoginProvider } from './common/pages/login/login-context';
import { ToastProvider } from './administration/common/components/toast/ToastPovider';
import { initializeMS } from './common/pages/login/ms-services';
import { KeyBoardHotKeysProvider } from './common/context/hotkeys.context';
import './App.css';
import { APIProvider } from '@vis.gl/react-google-maps';

function App() {
  useEffect(() => {
    initializeMS();
  }, []);

  return (
    <ToastProvider>
      <LoginProvider>
        <Provider store={store}>
          <KeyBoardHotKeysProvider>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY as string}>
              <ThemeWrapper>
                <RouterProvider router={router} />
                <Toast />
                <ImpersonatedToast />
              </ThemeWrapper>
            </APIProvider>
          </KeyBoardHotKeysProvider>
        </Provider>
      </LoginProvider>
    </ToastProvider>
  );
}

export default App;
