export const SUGGESTIONS = 'suggestions';
export const ADMIN_SIDEBAR = 'adminSidebar';
export const ADMIN_USERS = 'adminUsers';
export const ADMIN_USER_FILTERS = 'userAdminFilters';
export const TOAST = 'toast';
export const COUNTRIES = 'countriesSlice';
export const USECASE_PANEL = 'usecasePanel';
export const USECASES = 'usecases';
export const AUDIT = 'audit';
export const HCPS = 'hcps';
export const HCOS = 'hcos';
export const PRODUCTS = 'products';
export const USERS = 'users';
export const SUGGESTIONS_FILTER = 'suggestionsFilter';
export const PERSONAL_SETTINGS = 'personalSettings';
export const MAP = 'map';
export const DIRECTIONS = 'directions';
export const THEME = 'theme';
export const USER_DETAILS = 'userDetails';
export const USER_DETAILS_MODAL = 'userDetailsModal';
