import { AxiosResponse } from 'axios';
import { config } from '../../../common/config/configurations';
import { instance } from '../../common/apis/baseApi';
import { IPagination } from '../../interfaces/IPagination';

export const getCountries = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/countries`);
};

export const getActiveCountries = async (): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/countries`, {
    params: {
      $isActivated: true,
    },
  });
};

export const getCountriesWithPagination = async ({
  pageSize = 10,
  offset = 0,
  cursor = null,
}: IPagination) => {
  return instance.get(`${config.REACT_APP_API_VERSION}/countries`, {
    params: {
      page_size: pageSize,
      offset,
      cursor,
      $count: true,
    },
  });
};

export const activateCountry = async (countryId: string): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/countries/${countryId}/activate`);
};

export const deactivateCountry = async (countryId: string): Promise<AxiosResponse> => {
  return instance.post(`${config.REACT_APP_API_VERSION}/countries/${countryId}/deactivate`);
};

export const searchCountryAPI = async (searchQuery: string): Promise<AxiosResponse> => {
  return instance.get(`${config.REACT_APP_API_VERSION}/countries/search`, {
    params: {
      query: searchQuery,
    },
  });
};
