import React from 'react';
import { MarketShare } from '../component/marketshare';
import { IUsecaseConfigurations, IOption, IStepsText } from '../../../../../interfaces/IUsecases';

export const OPTIONS: IOption[] = [
  { label: 'GLOBAL_NOT_APPLICABLE', name: 'Not applicable' },
  { label: 'GLOBAL_VERY_LOW', name: 'Very Low' },
  { label: 'GLOBAL_LOW', name: 'Low' },
  { label: 'GLOBAL_MEDIUM', name: 'Medium' },
  { label: 'GLOBAL_HIGH', name: 'High' },
  { label: 'GLOBAL_VERY_HIGH', name: 'Very High' },
];

export const USECASE_CONFIGURATIONS: IUsecaseConfigurations = {
  CALL_PACING: {
    children: [
      {
        name: 'GLOBAL_SETTINGS',
        settings: [
          {
            title: 'NBA_CONFIGURATION_MCCP_COVERAGE',
            hasOptions: false,
          },
          {
            title: 'NBA_CONFIGURATION_PRIORITIES',
            hasOptions: false,
          },
          {
            title: 'NBA_CONFIGURATION_ROLE',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_RELATIVE_STRENGTH',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_ADOPTION_LADDER',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_TOP_ACCOUNTS',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_HCP_SPECIALTY',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_HCP_TYPE',
            hasOptions: true,
          },
          {
            title: 'NBA_CONFIGURATION_MARKET_SHARE',
            component: <MarketShare />,
          },
        ],
      },
    ],
  },
  EVENT_CONGRES: {
    children: [
      {
        name: 'GLOBAL_STEPS',
        settings: [
          {
            context: 'BEFORE',
            title: 'SEND_INVITATION_ABOUT_CONGRESS',
            controlComponent: null,
          },
          {
            context: 'BEFORE',
            title: 'SUGGEST_MEET_HCP',
            controlComponent: null,
          },
          {
            context: 'DURING',
            title: 'NOTIFY_KAM_ABOUT_EVENT',
            controlComponent: null,
          },
          {
            context: 'AFTER',
            title: 'SUGGEST_SATISFACTION_SURVEY',
            controlComponent: null,
          },
          {
            context: 'AFTER',
            title: 'SUGGEST_KAM_FOLLOW_UP',
            controlComponent: null,
          },
          {
            context: 'AFTER',
            title: 'REMINDER_TO_FOLLOW_UP',
            controlComponent: null,
          },
          {
            context: 'AFTER',
            title: 'SUGGEST_KAM_SECOND_FOLLOW_UP',
            controlComponent: null,
          },
          {
            context: 'AFTER',
            title: 'REMINDER_TO_SECOND_FOLLOW_UP',
            controlComponent: null,
          },
        ],
      },
    ],
  },
};

export const STEPS_TEXT: IStepsText = {
  BEFORE: 'GLOBAL_BEFORE_EVENT',
  DURING: 'GLOBAL_DURING_EVENT',
  AFTER: 'GLOBAL_AFTER_EVENT',
};
export const USECASE_OPTIONS = [
  { value: 'CALL_PACING', label: 'GLOBAL_CALL_PACING' },
  { value: 'EVENT_CONGRES', label: 'GLOBAL_EVENT_CONGRES' },
];
export const PRODUCT_REGEX =
  /product\.id==([a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12})/;
export const COUNTRY_REGEX =
  /country\.id==([a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12})/;
export const NAMES_REGEX = /^([\w\s]+)\s*-\s*([\w\s]+)$/;

export const PRIORITIES = [
  {
    translationKey: 'GLOBAL_ALL',
    value: 'ALL',
  },
  {
    translationKey: 'P1',
    value: 'P1',
  },
  {
    translationKey: 'P2',
    value: 'P2',
  },
  {
    translationKey: 'P3',
    value: 'P3',
  },
  {
    translationKey: 'P4',
    value: 'P4',
  },
  {
    translationKey: 'P5',
    value: 'P5',
  },
];

export const CALL_INVITED = 'callAcceptance';
export const CALL_ATTENDANCE = 'callAttendance';
export const EMAIL_ATTENDANCE = 'EmailAttendance';
export const EVENT_FOLLOWUP = 'eventFollowUp';
export const EVENT_SECOND_FOLLOWUP = 'eventSecondFollowUp';
